.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.Radar-heading {
  text-align: center;
}

::placeholder {
  color: #444;
}

button {
  font-size: '12px';
  background-color: silver;
  border-radius: 2px;
  height: 30px;
}

.Canvas {
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  max-width: 100%;
  max-height: 100%;
}

.Copyright-notice {
  font-size: 12px;
  color: silver;
  text-align: center;
  border-top: solid 1px silver;
  border-bottom: solid 1px silver;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.Pagination {
  display: flex;
  font-size: 16px;
  align-items: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.Controls {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 14px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.Controls-search {
  flex-grow: 1;
  font-size: 16px;
  border: solid 1px;
  padding: 5px;
  color: black;
}

.Controls-grams {
  width: 30px;
}

.Controls-slider {
  margin-right: 10px;
  -webkit-appearance: none;
  width: 100%;
  height: 1px;
  background: silver;
  outline: none;
  margin-bottom: 15px;
}

.Controls-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #e71c2c;
  cursor: pointer;
}

.dot {
  font-size: 25px;
  color: #e71c2c;
}

.Controls-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #e71c2c;
  cursor: pointer;
}

.Controls-label {
  width: 73px;
  flex-shrink: 0;
}

.Controls-pc {
  width: 35px;
  flex-shrink: 0;
}

.Controls-cal {
  width: 50px;
  flex-shrink: 0;
  text-align: right;
}

.Controls-macro {
  margin-right: 10px;
  width: '40px';
  flex-shrink: 0;
}

.Controls-button {
  margin-left: 10px;
  font-size: 16px;
}

.Page {
  color: silver;
  background-color: black;
  margin: 0 auto;
  max-width: 700px;
  line-height: 1.5;
}

th {
  font-weight: normal;
  color: white;
  font-size: 14px;
  vertical-align: middle;
}

td {
  font-size: 14px;
  vertical-align: middle;
  min-width: 30px;
}

h1 {
  font-size: 20px;
}

a {
  color: white;
}
